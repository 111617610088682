import React, { useState, useEffect, useRef } from 'react';
import './App.css'; // Для стилизации

function App() {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [username, setUsername] = useState('');
  const chatWindowRef = useRef(null); // Ссылка на окно чата
  const ws = useRef(null); // WebSocket сораняем в ref для постоянного использования
  
  useEffect(() => {
    // Загружаем имя пользователя их куки
    const savedUsername = document.cookie.replace(/(?:(?:^|.*;\s*)username\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    if (savedUsername) {
      setUsername(savedUsername);
    }

    ws.current = new WebSocket('ws://213.171.15.97:3000');

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      
      // Проверяем тип сообщения
      if (data.type === 'previousMessages') {
        // Добавляем предыдущие сообщения в чат
        setMessages(data.messages);
      } else if (data.type === "newMessage") {
        // Добавляем новое сообщение в список сообщений
        setMessages(prevMessages => [...prevMessages, { user: data.user, message: data.message, timestamp: data.timestamp }]);
      }
    };

    return () => {
      ws.current.close();
    };
  }, []);

  // Прокручиваем чат вниз при добавлении нового сообщения
  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);
  
  const sendMessage = () => {
    if (!username || !message) return;

    /* const ws = new WebSocket('ws://localhost:3000');
    ws.onopen = () => {
      ws.send(JSON.stringify({ user: username, message}));
      setMessage('');
    }; */

    const newMessage = { user: username, message };
    ws.current.send(JSON.stringify(newMessage));
    setMessage('') // Очищаем пле для ввода
  };

  // Обработка нажатия клавиши "Enter"
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  // Сохраняем имя пользователя в куки
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    document.cookie = `username=${e.target.value}; path=/; max-age=31536000`; // Срок действия кукри - 1 год
  }

  return (
    <div className="App">
      <h1>Messenger</h1>

      <div className="chat-window" ref={chatWindowRef}>
        {messages.map((msg, index) => (
          <div key={index} className="message-block">
            <div className="message-user">{msg.user}</div>
            <div className="message-text">{msg.message}</div>
            <div className="message-timestamp">{msg.timestamp}</div>
          </div>
        ))}
      </div>

      <div className="input-row">
        <input
          className="username-input"
          value={username}
          onChange={handleUsernameChange}
          placeholder="Enter your nickname"
        />
        <input
          className="message-input"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message"
          onKeyDown={handleKeyDown} /* Добавляем обработчик нажатия клавиши */
        />
        <button onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
}

export default App;
